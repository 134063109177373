<template>
<div class="page page-404">
  <div class="page-content">
    <div class="page-content_title">Упс...</div>
    <div class="page-content_subtitle">Такой страницы не существует</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped lang="scss">
.page-404 {
  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_title {
      font-size: 24px;
      font-weight: bold;
    }
    &_subtitle {
      font-size: 18px;
    }
  }
}
</style>
